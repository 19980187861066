<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    jobOffer: Object,
});

const { t } = useI18n();

const employmentType = { employed: t('Employed'), temp_worker: t('Temp Worker') };
const education = { secondary_school: t('Secondary school'), secondary_vocational_education: t('Secondary vocational education'), higher_vocational_education: t('Higher vocational education'), academic_education: t('Academic education') };

const tags = computed(() => {

    let tags = [];

    tags = [...tags, props.jobOffer.hours + t(' hours')];
    tags = [...tags, employmentType[props.jobOffer.employment] ?? props.jobOffer.employment];
        education[props.jobOffer.education] ? tags = [...tags, education[props.jobOffer.education]] : null;
    tags = [...tags, ...props.jobOffer.tags?.map((tag) => tag.title) ?? []];

    return tags;
},
);


</script>

<template>
    <div class='flex flex-wrap gap-4'>
        <div v-for='tag in tags' class='bg-blue-medium py-2 px-4 rounded-md text-lg'>
            {{ tag }}
        </div>
    </div>
</template>
